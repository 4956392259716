/* global $, ich */

const ich = require('./ICanHaz.min')

const jquery = require('./jquery-3.6.0.min')
window.$ = window.jQuery = jquery
require('./jquery-ui.min')

const SignatureBuilder = (function () {
  const accordion = $('#signature-builder')
  const form = $('#signature-details-form')
  const nameField = $('#name-field')
  const pronounField = $('#pronoun-field')
  const titleField = $('#title-field')
  const emailField = $('#email-field')
  const directPhoneField = $('#directphone-field')
  const mobileField = $('#mobile-field')
  const locationField = $('#location-field')
  const submit = $('#signature-details-submit')
  const outputHeader = accordion.find('h3').eq(1)
  const outputPreview = $('#output-preview')
  const locationData = {
    ldn: {
      phone: '+44 (0)20 7684 8444',
      // address: 'AnalogFolk, 20 Rosebery Avenue, London EC1R 4SX',
      address: 'AnalogFolk, 1 - 2 Bath Court, London EC1R 4SX',
    },
    nyc: {
      phone: '+1 917 444 5922',
      address:
        // 'AnalogFolk, 122 West 27th Street, 3rd Floor, New York, NY 10001',
          'AnalogFolk, C/O The Malin, 109 N 12th St 9th Floor, Brooklyn, NY 11249',
    },
    syd: {
      phone: '+612 9018 4144',
      address:
        'AnalogFolk, Level 6, 2-12 Foveaux St, Surry Hills, Sydney, NSW 2010',
    },
    hkg: {
      phone: '+852 5239 8278',
      address:
        'AnalogFolk, East Exchange Tower, 38 Leighton Rd, Causeway Bay, Hong Kong',
    },
    ams: {
      phone: '+31 6 11245254',
      address:
        'AnalogFolk, Nieuwezijds Voorburgwal 296-298, Amsterdam, 1012 AB',
    },
    sg: {
      phone: '+852 223 44200',
      address:
        'AnalogFolk, 10 Anson Road #29-07, International Plaza, Singapore, 079903',
    },
    sha: {
      phone:
        'AnalogFolk, Room 3001, No.35 Loushanguan Road, Changning District, Shanghai, China 200336',
      address: '+852 223 44200',
    },
  }

  function generateEmail() {
    let name = nameField.val()
    let nameSanitised = name.trim().toLowerCase().latinise().replace(' ', '.')
    let email

    nameSanitised = nameSanitised.replace(/[^\.a-z]+/g, '')

    email = nameSanitised + '@analogfolk.com'

    emailField.val(email)
  }

  function validateForm() {
    const name = nameField.val().trim()
    const loc = locationField.val()

    if (name.length === 0 || loc.length === 0) {
      submit.attr('disabled', 'disabled')
      accordion.accordion('disable')
      outputHeader.addClass('disabled')
      return false
    } else {
      submit.removeAttr('disabled')
      return true
    }
  }

  function generateOutput() {
    const formData = {}
    const loc = locationField.val()
    let output

    formData['name'] = nameField.val().trim()
    formData['pronoun'] = pronounField.val().trim()
    formData['title'] = titleField.val().trim()
    formData['email'] = emailField.val().trim()
    formData['directPhone'] = directPhoneField
      .val()
      .trim()
      .replace(/[^\+ ()0-9]+/g, '')
      .replace(/\s{2,}/g, ' ')
    formData['directPhoneLink'] = formData['directPhone'].replace(
      /[^\+0-9]+/g,
      ''
    )
    formData['mobilePhone'] = mobileField
      .val()
      .trim()
      .replace(/[^\+ ()0-9]+/g, '')
      .replace(/\s{2,}/g, ' ')
    formData['mobilePhoneLink'] = formData['mobilePhone'].replace(
      /[^\+0-9]+/g,
      ''
    )
    formData['phone'] = locationData[loc]['phone'] || ''
    formData['phoneLink'] = formData['phone'].replace(/[^\+0-9]+/g, '')
    formData['address'] = locationData[loc]['address']

    output = ich.emailOutputTpl(formData)
    outputPreview.html(output)

    selectContents(outputPreview[0])
  }

  function generateSignature() {
    const formIsValid = validateForm()

    if (formIsValid) {
      generateOutput()
      accordion.accordion('enable')
      outputHeader.removeClass('disabled')
      outputHeader.click()
    }
  }

  function selectContents(element) {
    setTimeout(function () {
      let sel, range
      if (window.getSelection && document.createRange) {
        range = document.createRange()
        range.selectNodeContents(element)
        sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }, 10)
  }

  function init() {
    accordion.accordion({
      heightStyle: 'content',
      icons: false,
    })
    accordion.accordion('disable')

    form.on('submit', function (e) {
      generateSignature()
      e.preventDefault()
    })

    form.on('reset', function () {
      accordion.accordion('disable')
      outputHeader.addClass('disabled')
      submit.attr('disabled', 'disabled')
    })

    generateSignature()

    nameField.on('keyup cut paste blur change', function () {
      generateEmail()
      validateForm()
    })

    locationField.on('change', function () {
      validateForm()
    })

    outputPreview.on('click', function () {
      selectContents(this)
    })
  }

  return {
    init: function () {
      init()
    },
  }
})()

$(document).ready(function () {
  SignatureBuilder.init()
})
